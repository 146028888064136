// Columns of parking table
export const PARKINGS_TABLE_COLUMNS = Object.freeze([
    {
        id: 'id',
        label: 'Parkolójegy ID',
        width: '20%',
        sortable: false,
    },
    {
        id: 'user_id',
        label: 'Felhasználó ID',
        width: '10%',
        sortable: true,
    },
    {
        id: 'paid',
        label: 'Fizetve',
        width: '10%',
        sortable: true,
    },
    {
        id: 'amount',
        label: 'Összeg (Ft)',
        width: '10%',
        sortable: false,
    },
    {
        id: 'status',
        label: 'Státusz',
        width: '10%',
        sortable: true,
    },
    {
        id: 'entryTime',
        label: 'Belépés ideje',
        width: '20%',
        sortable: true,
    },
    {
        id: 'exitTime',
        label: 'Kilépés ideje',
        width: '20%',
        sortable: true,
    },
    {
        id: 'detailsIcon',
        label: '',
        width: '0%',
        sortable: false,
    },
]);

// Columns of users table
export const USERS_TABLE_COLUMNS = Object.freeze([
    {
        id: 'lastname',
        label: 'Vezetéknév',
        width: '15%',
        sortable: true,
    },
    {
        id: 'firstname',
        label: 'Keresztnév',
        width: '15%',
        sortable: true,
    },
    {
        id: 'email',
        label: 'E-mail cím',
        width: '20%',
        sortable: false,
    },
    {
        id: 'registration',
        label: 'Regisztráció',
        width: '10%',
        sortable: true,
    },
    {
        id: 'birthdate',
        label: 'Születési dátum',
        width: '10%',
        sortable: false,
    },
    {
        id: 'lang',
        label: 'Nyelv',
        width: '5%',
        sortable: true,
    },
    {
        id: 'role',
        label: 'Jogosultság',
        width: '5%',
        sortable: true,
    },
    {
        id: 'vipurl',
        label: 'Dolgozói QR',
        width: '15%',
        sortable: true,
    },
    {
        id: 'lurdylifemember',
        label: 'Lurdy Life tag',
        width: '5%',
        sortable: false,
    },
    {
        id: 'editIcon',
        label: '',
        width: '0%',
        sortable: false,
    },
    {
        id: 'deleteIcon',
        label: '',
        width: '0%',
        sortable: false,
    },
]);

// Columns of payments table
export const PAYMENTS_TABLE_COLUMNS = Object.freeze([
    {
        id: 'orderref',
        label: 'Befizetés ID',
        width: '20%',
        sortable: false,
    },
    {
        id: 'amount',
        label: 'Összeg (Ft)',
        width: '10%',
        sortable: false,
    },
    {
        id: 'email',
        label: 'E-mail',
        width: '30%',
        sortable: false,
    },
    {
        id: 'userId',
        label: 'Felhasználó ID',
        width: '10%',
        sortable: false,
    },
    {
        id: 'ticketId',
        label: 'Parkolójegy ID',
        width: '15%',
        sortable: false,
    },
    {
        id: 'status',
        label: 'Státusz',
        width: '15%',
        sortable: false,
    },
    {
        id: 'parkingDetails',
        label: '',
        width: '0%',
        sortable: false,
    },
    {
        id: 'userDetails',
        label: '',
        width: '0%',
        sortable: false,
    },
]);

// Columns of payments of a parking ticket on parking details page
export const PAYMENTS_OF_TICKET_COLUMNS = Object.freeze([
    {
        id: 'orderref',
        label: 'Befizetés ID',
        width: '25%',
        sortable: false,
    },
    {
        id: 'amount',
        label: 'Összeg (Ft)',
        width: '15%',
        sortable: false,
    },
    {
        id: 'email',
        label: 'E-mail',
        width: '30%',
        sortable: false,
    },
    {
        id: 'userId',
        label: 'Felhasználó ID',
        width: '15%',
        sortable: false,
    },
    {
        id: 'status',
        label: 'Státusz',
        width: '15%',
        sortable: false,
    },
    {
        id: 'detailsIcon',
        label: '',
        width: '0%',
        sortable: false,
    },
]);

// Columns of audit logs table
export const LOGS_TABLE_COLUMNS = Object.freeze([
    {
        id: 'user_id',
        label: 'Felhasználó ID',
        width: '15%',
        sortable: false,
    },
    {
        id: 'action',
        label: 'Log típus',
        width: '15%',
        sortable: false,
    },
    {
        id: 'ip',
        label: 'IP cím',
        width: '10%',
        sortable: false,
    },
    {
        id: 'created_at',
        label: 'Dátum',
        width: '10%',
        sortable: false,
    },
    {
        id: 'log',
        label: 'Log',
        width: '50%',
        sortable: false,
    },
    {
        id: 'more',
        label: '',
        width: '0%',
        sortable: false,
    },
]);
